import { FeatureResponse } from "@9amhealth/openapi";
import FunnelKey from "src/constants/funnelKey";
import { FUNNEL_QUESTIONNAIRE_ELIGIBILITY } from "src/constants/misc";
import { SignupCustomCampaignName } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import FactorySignupCustomCampaign from "src/ui/components/SignupCustomContent/campaigns/FactorySignupCustomCampaign";

enum EligibilityQuestionId {
  employer = "nnr4MisyYRqS",
  healthPlan = "Xr1URwgDrLB8"
}

enum EligibilityEmployerChoiceId {
  lilly = "aawBnc5U8xA8",
  stateOfIndiana = "PxcU4opoCxXT",
  qwikTrip = "oZlpyMeJMxwX",
  prescryptive = "xBdIqSpWXKNF",
  firstChoice = "1nJ65cyhh18G",
  generalPlastics = "XXhxi0BA1XZO",
  jjSafety = "YWlwgEuAOeI7",
  nimnicht = "KdoCwOdxKMsp",
  weAreBlood = "2PclwiU3z3G2",
  exceptionalMotorcars = "wbcgBT1kCY5x",
  unitedTrailers = "e8Y25dGSuvTw",
  kineticGroup = "KS7beV6g2Ll7",
  jimSchmidtChevrolet = "TDyAAMxBXc5v",
  kwestGroup = "g7zSaWeQqDFv",
  tkg = "eFiS5CSR8ZNW",
  wipfli = "hroIvEv66bHy",
  apple = "AjiuetUcuy5D",
  amazon = "ALXY6RYKBVTr"
}

enum EligibilityHealthPlanChoiceId {
  lilly = "eEXojUUbdJef",
  anthemIndiana = "nvwPwhoPWjJv",
  evernorth = "EhJryoxsCGQQ",
  transcarent = "CAEVcKrBxBvN"
}

const eligibilityEmployerChoiceValues = {
  [EligibilityEmployerChoiceId.lilly]: "Lilly",
  [EligibilityEmployerChoiceId.stateOfIndiana]: "State of Indiana",
  [EligibilityEmployerChoiceId.qwikTrip]: "Kwik Trip",
  [EligibilityEmployerChoiceId.prescryptive]: "Prescryptive",
  [EligibilityEmployerChoiceId.firstChoice]: "First Choice",
  [EligibilityEmployerChoiceId.generalPlastics]: "General Plastics",
  [EligibilityEmployerChoiceId.jjSafety]: "JJ Safety",
  [EligibilityEmployerChoiceId.nimnicht]: "Nimnicht",
  [EligibilityEmployerChoiceId.weAreBlood]: "We Are Blood",
  [EligibilityEmployerChoiceId.exceptionalMotorcars]: "Exceptional Motorcars",
  [EligibilityEmployerChoiceId.unitedTrailers]: "United Trailers",
  [EligibilityEmployerChoiceId.kineticGroup]: "Kinetic Group",
  [EligibilityEmployerChoiceId.jimSchmidtChevrolet]: "Jim Schmidt Chevrolet",
  [EligibilityEmployerChoiceId.kwestGroup]: "KWest Group",
  [EligibilityEmployerChoiceId.tkg]: "TKG",
  [EligibilityEmployerChoiceId.wipfli]: "Wipfli",
  [EligibilityEmployerChoiceId.apple]: "Apple",
  [EligibilityEmployerChoiceId.amazon]: "Amazon"
};

const eligibilityHealthPlanChoiceValues = {
  [EligibilityHealthPlanChoiceId.lilly]: "Lilly",
  [EligibilityHealthPlanChoiceId.anthemIndiana]: "Anthem Indiana",
  [EligibilityHealthPlanChoiceId.evernorth]: "Evernorth",
  [EligibilityHealthPlanChoiceId.transcarent]: "Transcarent"
};

const campaignInstances = () => [
  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.lillyTeam,
    prettyName: "Lilly Team",
    funnelKey: FunnelKey.lillyTeam,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/534da4ef5cfc1e325a159785ba16c741fcfc3d0a-631x344.svg",
    logoSize: "small",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: EligibilityQuestionId.employer,
          fieldType: "choice",
          fieldValue: {
            choiceId: EligibilityEmployerChoiceId.lilly,
            value:
              eligibilityEmployerChoiceValues[EligibilityEmployerChoiceId.lilly]
          }
        },
        {
          questionId: EligibilityQuestionId.healthPlan,
          fieldType: "choice",
          fieldValue: {
            choiceId: EligibilityHealthPlanChoiceId.lilly,
            value:
              eligibilityHealthPlanChoiceValues[
                EligibilityHealthPlanChoiceId.lilly
              ]
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.stateOfIndiana,
    prettyName: "State of Indiana",
    funnelKey: FunnelKey.stateOfIndiana,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/536581e6692a17af77dc2c15085a9e3b019c5668-3840x848.png",
    logoSize: "large",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: EligibilityQuestionId.employer,
          fieldType: "choice",
          fieldValue: {
            choiceId: EligibilityEmployerChoiceId.stateOfIndiana,
            value:
              eligibilityEmployerChoiceValues[
                EligibilityEmployerChoiceId.stateOfIndiana
              ]
          }
        },
        {
          questionId: EligibilityQuestionId.healthPlan,
          fieldType: "choice",
          fieldValue: {
            choiceId: EligibilityHealthPlanChoiceId.anthemIndiana,
            value:
              eligibilityHealthPlanChoiceValues[
                EligibilityHealthPlanChoiceId.anthemIndiana
              ]
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.evernorth,
    prettyName: "Evernorth",
    funnelKey: FunnelKey.evernorth,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/eca72b7fe025eb6e20dc82459a575706d2666423-438x77.svg",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: EligibilityQuestionId.healthPlan,
          fieldType: "choice",
          fieldValue: {
            choiceId: EligibilityHealthPlanChoiceId.evernorth,
            value:
              eligibilityHealthPlanChoiceValues[
                EligibilityHealthPlanChoiceId.evernorth
              ]
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.kwikTrip,
    prettyName: "Kwik Trip",
    funnelKey: FunnelKey.kwikTrip,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/cc79972cfb5a8c427e39373deae3f50d4369cd66-375x60.png",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: EligibilityQuestionId.employer,
          fieldType: "choice",
          fieldValue: {
            choiceId: EligibilityEmployerChoiceId.qwikTrip,
            value:
              eligibilityEmployerChoiceValues[
                EligibilityEmployerChoiceId.qwikTrip
              ]
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.Prescryptive,
    prettyName: "Prescryptive",
    funnelKey: FunnelKey.prescryptive,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/ef478da257d2f1cbc3482d519d243617ab42e211-349x138.webp",
    filterAnswerOptions: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: EligibilityQuestionId.employer,
          includeOptions: [
            EligibilityEmployerChoiceId.prescryptive,
            EligibilityEmployerChoiceId.firstChoice,
            EligibilityEmployerChoiceId.generalPlastics
          ]
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.Universal,
    prettyName: "Universal",
    funnelKey: FunnelKey.universal,
    getSubscriptionFromSuggestedTreatmentPlan: true,
    personalisedFinalSuccessScreen: true
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.Lilly,
    prettyName: "Lilly",
    funnelKey: FunnelKey.lilly,
    getSubscriptionFromSuggestedTreatmentPlan: true,
    personalisedFinalSuccessScreen: true
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.SmithRx,
    prettyName: "SmithRx",
    funnelKey: FunnelKey.smithrx,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/ebe6b7808efc33fe80bb8a5259f867965cc656ca-180x34.svg",
    filterAnswerOptions: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: EligibilityQuestionId.employer,
          includeOptions: [
            EligibilityEmployerChoiceId.jjSafety,
            EligibilityEmployerChoiceId.nimnicht,
            EligibilityEmployerChoiceId.weAreBlood,
            EligibilityEmployerChoiceId.exceptionalMotorcars,
            EligibilityEmployerChoiceId.unitedTrailers,
            EligibilityEmployerChoiceId.kineticGroup,
            EligibilityEmployerChoiceId.jimSchmidtChevrolet,
            EligibilityEmployerChoiceId.kwestGroup,
            EligibilityEmployerChoiceId.tkg
          ]
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.wipfli,
    prettyName: "Wipfli",
    funnelKey: FunnelKey.wipfli,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/6d4ff0f3fc17c198204eb23366484630328d0437-924x301.png",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: EligibilityQuestionId.employer,
          fieldType: "choice",
          fieldValue: {
            choiceId: EligibilityEmployerChoiceId.wipfli,
            value:
              eligibilityEmployerChoiceValues[
                EligibilityEmployerChoiceId.wipfli
              ]
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.transcarent,
    prettyName: "Transcarent",
    funnelKey: FunnelKey.transcarent,
    availabilityChecks: {
      blockAnonymousUsers: true,
      textContext: "error"
    },
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: EligibilityQuestionId.healthPlan,
          fieldType: "choice",
          fieldValue: {
            choiceId: EligibilityHealthPlanChoiceId.transcarent,
            value:
              eligibilityHealthPlanChoiceValues[
                EligibilityHealthPlanChoiceId.transcarent
              ]
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.amazon,
    prettyName: "Amazon",
    funnelKey: FunnelKey.amazon,
    availabilityChecks: {
      featureFlag: "AMAZON_WEIGHTLOSS" as FeatureResponse.feature,
      textContext: "notAvaialbleYet"
    },
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/93fb16874daaebdb21161feaecca6812dbf51473-739x129.svg",
    logoSize: "large",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: EligibilityQuestionId.employer,
          fieldType: "choice",
          fieldValue: {
            choiceId: EligibilityEmployerChoiceId.amazon,
            value:
              eligibilityEmployerChoiceValues[
                EligibilityEmployerChoiceId.amazon
              ]
          }
        }
      ]
    }
  })
];

const SignupCustomCampaignGenericInstances = campaignInstances().map((c) =>
  c.get()
);

export default SignupCustomCampaignGenericInstances;
